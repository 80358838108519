import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import MinimalLayout from 'components/MinimalLayout';
import Heading from 'components/Heading/Heading';
import Text from 'components/Text/Text';
import MinimalFooter from 'components/Footer/MinimalFooter';
import { theme } from 'styles/styled';
import { useIsRtl } from 'hooks/useCurrentLocale';
import {
  translationsFactory,
  TranslationResult,
  TranslationsContext,
} from 'settings/i18n';

const MaintenanceWrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
`;

const MaintenanceHeading = styled(Heading)`
  font-size: ${theme.fontSize.pageTitleMobile};
  text-align: center;

  @media ${theme.devices.small} {
    font-size: ${theme.fontSize.pageTitle};
  }

  @media ${theme.devices.medium} {
    font-size: ${theme.fontSize.headingBig};
  }
`;

const ApologiesText = styled(Text)`
  text-align: center;
  margin: 48px 12px 0 12px;

  @media ${theme.devices.medium} {
    margin: 48px 12px 124px 12px;
  }
`;

const Maintenance = ({ data }: { data: TranslationResult }) => {
  const isRtl = useIsRtl();
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data?.homepage?.translation,
      },
    },
  });
  return (
    <TranslationsContext.Provider value={t}>
      <MinimalLayout>
        <MaintenanceWrapper>
          <MaintenanceHeading
            isRtl={isRtl}
            size="headingBig"
            color="primary"
            weight="medium"
            letterSpacing="normal">
            {t('theSite')}{' '}
            <MaintenanceHeading
              isRtl={isRtl}
              size="headingBig"
              color="dark"
              weight="light"
              letterSpacing="normal">
              {t('isCurrently')}
            </MaintenanceHeading>
            <br />
            <MaintenanceHeading
              isRtl={isRtl}
              size="headingBig"
              color="dark"
              weight="light"
              letterSpacing="normal">
              {t('downForMaintenance')}
            </MaintenanceHeading>
          </MaintenanceHeading>

          <ApologiesText isRtl={isRtl}>
            {t('weApologizeForAnyInconveniencesCaused')}
            <br />
            {t('pleaseCheckBackSoon')}
          </ApologiesText>
        </MaintenanceWrapper>
        <MinimalFooter />
      </MinimalLayout>
    </TranslationsContext.Provider>
  );
};

export default Maintenance;

export const query = graphql`
  query($locale: String!) {
    metadata: wpPage(
      slug: { regex: "/\\b(\\w*maintenance\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }
    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
      ...MaintenanceTranslation
    }
  }
`;
